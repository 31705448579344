<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="WhatsApp" :subtitle="'Templates'" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col">
          <form @submit.prevent="fetchTemplates(1)" class="form-group col-6 px-0 mb-4 mx-auto">
            <div class="input-group">
              <input placeholder="" type="text" class="form-control search-form" v-model="searchQuery"
                ref="searchInput" />
              <div class="input-group-prepend">
                <button class="btn icon_btn m-0">
                  <span class="material-symbols-outlined"> search </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12">
              <div class="row mb-4">
                <div class="col-md-8 col-lg-8 col-sm-12 all-actions" v-if="templates.length">
                  <div class="action">
                    <div class="control-group opt-2 to-left">
                      <label class="control control-checkbox" :for="'template-checka-all'">
                        <input
                          v-model="selectAllTemplates"
                          type="checkbox"
                          class="custom-control-input"
                          :id="'template-checka-all'"
                        />
                        <div class="control_indicator"></div>
                      </label>
                    </div>
                    {{ $t("generic-str.all") }}
                  </div>
                  <div class="action">
                    <button @click="deleteAll" class="btn-delete-all">
                      <span class="material-symbols-outlined to-left">delete_forever</span>
                      <span>
                        {{ selectedTemplates.length }}
                        Selecionados
                      </span>
                    </button>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-12">
                  <div class="input-group">
                    <div class="">
                      <router-link :to="'/rcs/templates/new'" class="btn btn-primary btn-add-template">
                        <span class="material-symbols-outlined">add_circle</span><span class="span-text">{{ $t('sms.templates.lbl-create') }}</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="fetched">
            <div class="card card-templates" v-for="(list, i) in templates" :key="list.id">
              <div class="card-header">
                <div class="container-fluid">
                  <div class="row">
                    <div class="control-group opt-2 col-md-1 col-sm-1 col-lg-1 p-0">
                      <label
                        class="control control-checkbox"
                        :for="'template-check' + i"
                      >
                        <input
                          v-model="selectedTemplates"
                          type="checkbox"
                          class="custom-control-input"
                          :id="'template-check' + i"
                          :value="list"
                        />
                        <div class="control_indicator"></div>
                      </label>
                    </div>
                    <div class="col-10 template-head-infos p-0">
                      {{ list.name }}
                    </div>
                    <div class="col-1 d-flex">
                      <span :id="'visibility-n-' + i" class="material-icons visibility-icon"
                        @click="showPreview(i, list)">visibility</span>
                      <dropdown>
                        <template v-slot:text>
                          <span class="material-symbols-outlined title">
                            more_vert
                          </span>
                        </template>
                        <template #items>
                          <button class="dropdown-item" @click="deleteTemplate(list)">
                            {{ $t('generic-str.remove') }}
                          </button>
                          <button class="dropdown-item" @click="editTemplate(list)">
                            {{ $t('generic-str.edit') }}
                          </button>
                        </template>
                      </dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row-date">
                  <span class="material-symbols-outlined">autorenew</span><span>{{list.created_at | formatDate24 }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="fetched">
            <pagination v-model="currentPage" :total-pages="pages" @change="fetchTemplates" />
          </div>
        </div>
        <div v-if="fetched && templates.length == 0" class="text-center w-100 no-item">
          <span class="material-symbols-outlined d-block">palette</span>
          <p class="d-block">Nenhum template encontrado</p>
        </div>
        <!-- Preview Column -->
        <div v-if="fetched && templates.length" class="col-lg preview-div">
          <div class="preview-wrapper">
            <div class="card card-preview" v-if="!showingPreview" style="background: #fff;">
              <div class="card-body">
                <p class="card-text footer-item">
                  Clique em um dos '<span class="material-icons visibility-icon">visibility</span>' para pré-visualizar o
                  seu template 😀
                </p>
              </div>
            </div>
            <TemplatePreview :template="this.template" />
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
        <!--/Preview Column -->
        <div class="text-center py-5 m-top-80" v-if="!fetched">
          <img src="assets/img/loading.gif">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import RCSService from '@/services/rcs.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/PaginationComponent.vue';
import Dropdown from '@/components/Dropdown.vue';
import TemplatePreview from '@/components/rcs/TemplatePreview.vue';
import moment from 'moment-timezone';

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    Pagination,
    Dropdown,
    TemplatePreview,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/jv/reports/history',
        2: '/whatsapp/jv/templates',
      },
      fetchPreview: Boolean,
      searchQuery: null,
      isSending: false,
      fetched: false,
      templates: [],
      whatsNumbers: [],
      whatsapp_identification: '',
      whatsapp_phone: '',
      lastWAI: '',
      client: {},
      form: {
        page: 1,
      },
      pages: 1,
      currentPage: 1,
      waba_body: '',
      waba_header: '',
      waba_header_type: '',
      waba_footer: '',
      waba_img: '',
      waba_has_btn: Boolean,
      waba_btn_type: '',
      waba_btn_url: '',
      waba_btn_text: '',
      waba_btn_quick_replies: [],
      template: {
        buttons: {},
      },
      showingPreview: false,
      selectedTemplates: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    user() {
      return this.$store?.state?.auth?.user;
    },
    selectAllTemplates: {
      get() {
        if (this.templates.length || this.templates.length !== 0) {
          return this.selectedTemplates.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.templates.forEach((template) => {
            selected.push(template);
          });
        }

        this.selectedTemplates = selected;
      },
    },
  },
  created() {
    this.$root.$on('template.submit', this.fetch);
    this.fetchTemplates(1);
  },
  methods: {
    deleteAll() {
      const arrayPush = [];
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: `Tem certeza que deseja remover ${this.selectedTemplates.length} templates?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          this.selectedTemplates.forEach((template) => {
            arrayPush.push(
              RCSService.deleteTemplate(this.account.id, template.id),
            );
          });
          this.selectedTemplates = [];
          Promise.all(arrayPush)
            .then(
              (responses) => {
                console.log(responses);
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: 'Templates removido com sucesso!',
                  type: 'success',
                });
                this.fetchTemplates(1);
              },
              (error) => {
                this.content = error;
                this.$toast.show({
                  title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
                  content: 'Erro interno, por favor, tente novamente',
                  type: 'danger',
                });
              },
            )
            .finally(() => {
              this.fetched = true;
            });
        }
      });
    },
    showPreview(previewIndex, data) {
      this.fetchPreview = false;
      this.showingPreview = true;
      const previewValue = previewIndex;
      this.template = data;
      this.template.body = JSON.parse(data.body);
      console.log('Preview: ', data);
      this.$forceUpdate();

      const elems = document.querySelectorAll('.visibility-icon');
      [].forEach.call(elems, (el) => {
        el.classList.remove('active');
      });
      const visibilityIcon = document.getElementById(`visibility-n-${previewIndex}`);
      visibilityIcon.classList.add('active');

      this.fetchPreview = true;
    },
    callPreview(id, data) {
      console.log(data);
      this.$root.$emit('show.modal', id, data);
      this.$root.$emit('updated.previewwhats', data.body);
    },
    editTemplate(template) {
      template.body = this.isJsonString(template.body) ? JSON.parse(template.body) : template.body;
      this.$router.push({
        name: 'EditTemplateRCS',
        params: { template },
      });
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    deleteTemplate(list) {
      const id = list.id;
      const blockquoteStyle =
        'padding: 1rem;font-size: 0.85rem;background-color: #f2f2f2;font-style: italic;line-height: 25px;text-align: start;width: 90%;margin: 1rem auto 0;border-radius: 6px;';
      Swal.fire({
        title: 'Remover template',
        html: `Tem certeza que gostaria de remover o template:
        <blockquote style="${blockquoteStyle}">
          <strong>ID:</strong> ${id}<br>
          <strong>Nome do template:</strong> ${list.name}<br>
          <strong>Criado em:</strong> ${moment(list.created_at).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}<br>
        </blockquote>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetchedItens = false;
          this.fetched = false;
          Promise.all([
            RCSService.deleteTemplate(this.account.id, id),
          ])
            .then(
              (responses) => {
                console.log(responses);
                this.fetched = true;
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: 'Templates removido com sucesso!',
                  type: 'success',
                });
                this.fetchTemplates(1);
              },
              (error) => {
                this.content = error;
                this.$toast.show({
                  title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
                  content: 'Erro interno, por favor, tente novamente',
                  type: 'danger',
                });
              },
            )
            .finally(() => {
              this.fetchedItens = true;
              this.fetched = true;
            });
        }
      });
    },
    fetchTemplates(page) {
      this.fetchedItens = false;
      this.fetched = false;
      this.templates = [];
      this.currentPage = page;
      RCSService.getTemplates({
        filter: { name: this.searchQuery, account_id: this.account.id }, page,
      }).then((response) => {
        console.log('Templates');
        console.log(response);
        this.fetched = true;
        if (response && response.data) {
          // response.data.content.forEach((template) => {
          //   if (template.status !== 'DELETED') {
          //     this.templates.push(template);
          //   }
          // });
          this.templates = response.data.data;
          console.log('Search temp: ', this.templates);
        }
        this.pages = response.data.last_page || 0;
        this.fetchedItens = true;
      }, (error) => {
        this.content = error;
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
          content: 'Erro interno, por favor, tente novamente',
          type: 'danger',
        });
        this.fetched = true;
      }).finally(() => {
        this.fetchedItens = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}

/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}

.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }

  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }

  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }

  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left: unset;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

.darkmode .rebranding .form-control.search-form,
.rebranding .form-control.search-form {
  border-right: unset !important;
}

.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left: unset;
}

.select-all-checkbox {
  width: fit-content;
  display: flex;
  gap: 10px;
  margin: 1rem;
}

.template-opt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.template-opt ::v-deep .dropdown a {
  display: flex;
}

.template-head-infos {
  display: flex;
  align-items: center;
}

.template-head-infos span>span {
  font-weight: 400;
}
.card-templates {
  box-shadow: none;
  border: solid 1px #E4E7EC;
}
.card-templates .card-text {
  width: fit-content;
  background-color: #f2ecfd;
  padding: 4px 10px;
}

.darkmode .card-templates .card-text {
  background-color: var(--background-3);
}

.card-templates .card-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.card-templates .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-templates .card-footer small {
  margin-left: auto;
  color: var(--gray-font-color);
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}

/* Phone preview */
.preview-div {
  display: flex;
  // position: relative;
  /* align-items: center; */
  position: sticky;
  top: 100px;
  height: fit-content;
}

.preview-wrapper {
  width: 100%;
  height: fit-content;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.visibility-icon {
  color: var(--gray-font-color);
  cursor: pointer;
  transition: .5s;
}

.visibility-icon.active {
  color: var(--clr-yup-purple) !important;
}

.card-preview {
  .footer-item {
    font-size: 1.15rem;

    span.material-icons {
      transform: translateY(5px);
    }
  }
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }

  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    // margin-top: 10%; // Position-sticky
    z-index: -1;
  }

  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: 512px;
  }

  .card-preview {
    position: absolute;
    width: 410px;
    // top: 200px;
    right: 20%;
    // margin-top: 4rem;
    margin-bottom: 0 !important;
  }

  .card-preview .card-body {
    padding: 1rem 1rem 2rem;

    .img-loading {
      width: 378px;
      height: 189px;
    }
  }
}

.sort-group,
.create-group {
  width: auto;
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}

.preview-message-btns i {
  color: #00a5f4;
}

.preview-message-btns span {
  display: flex;
  margin-bottom: 8px;
  justify-content: center;
  gap: 1rem;
  margin-top: .725rem;
}

.btn-link-preview .btn {
  box-shadow: 0px 2px 3px #0000001A;
}

.btn-delete {
  padding: 5px;
}

.flag {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  max-width: 20px;
}

.undefined_lang {
  border: 2px solid var(--foreground);
  border-radius: 50%;
  padding: .1rem;
}

.image-container {
  text-align: center;
  margin-bottom: 10px;
  background: #eff2f7;
  padding: 25px
}

.image-container img {
  width: 20%;
}

.title-block {
  width: 280px;
  display: inline-block;
}

.flag {
  width: 20px;
  height: 20px;
}
.btn-add-template {
  background: none !important;
  border: none !important;
  color: var(--clr-yup-purple) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  display: flex !important;
  padding: 0px !important;
  flex-direction: row-reverse;
}
.span-text {
  margin-right: 5px;
}
.p-0 {
  padding: 0px !important;
}
.row-date {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.row-date span {
  color: #868fa1;
  font-weight: 400;
  font-size: 14px;
}
.row-date .material-symbols-outlined {
  font-size: 18px;
  margin-top: 2px;
}
.m-top-80 {
  margin-top: 80px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
}
/* Preview */
.preview-wrapper {
  position: sticky;
  top: 90px;
}
.card-preview {
  padding: 10px;
  border-radius: 8px;
}
.card-preview .card {
  box-shadow: none !important;
  border-radius: 15px !important;
  overflow: hidden;
}
.card-preview .card-body {
  padding: 8px !important;
}
.card-preview .media {
  overflow: hidden;
  border-radius: 8px;
}
.type-row .card{
  flex: 1;
  margin: 3px;
}

.image-default-header {
  height: 150px;
  width: 100%;
  object-fit: cover;
}
.type-row span.material-symbols-outlined {
  font-size: 25px !important;
}
.btn-whats-message {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-top-color: #e0e0e0 !important;
  border-top-width: 1px !important;
  border-top: solid;
}
.btn-whats-message a {
  display: block;
  justify-content: center;
  padding: 5px;
  color: #389dc9 !important;
  font-weight: 500;
  font-size: 15px;
  margin-right: 5px;
  margin-top: 3px;
}
.btn-whats-message a span {
  font-size: 15px;
  margin-top: 5px;
  margin-right: 5px;
}
.card-img {
  padding: 5px;
}
.card-img-top {
  border-radius: 10px;
}
.card-catalog .card-img {
  background: #f6f6f6;
  padding: 5px;
}
.card-catalog .prod-preview {
  display: flex;
  height: auto;
}
.prod-preview img{
  padding: 10px;
  width: 70px;
  background: #000;
}
.prod-preview h3 {
  font-size: 16px;
  margin: 0;
  margin-left: 10px;
}
.prod-preview p {
  color: #adadad;
  font-weight: 100;
  margin-left: 10px;
  margin-bottom: 0px;
}
.card-catalog .card-text {
  margin-bottom: 0px;
}
.footer-item {
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 0px;
}
.card-text {
  padding-bottom: 15px;
}
.time-span {
  position: absolute;
  right: 10px;
  color: #ccc;
  font-size: 13px;
  bottom: 5px;
}
.type-row .card-header.active {
  background: #752de6 !important;
}
.type-row .card-header:hover {
  background: #d0d0d0 !important;
}
.type-row .card-header.active button span{
  color: #fff !important;
}
.type-row .card-header.active button .wrapper-title p{
  color: #fff;
}
.offer-icon {
  width: 50px;
  height: 50px;
  background: #a0f7b3;
  border-radius: 50%;
  margin-left: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.offer-icon span{
  color: #47ad5e;
}
.offer-code {
  color: #c6c6c6;
}
.padding-b-0{
  padding-bottom: 0px !important;
}
.padding-t-0{
  padding-top: 0px !important;
}
.btn-whats-message-coupon a span {
  font-size: 15px !important;
  margin-top: 3px !important;
  margin-right: 5px;
}
.btn-whats-message-coupon a {
  font-size: 13px !important;
}
.h-160 {
  height: 160px !important;
}
.card-img-top {
  object-fit: cover;
  background: #000;
}
.video-header {
  width: 271px;
  height: 180px;
  background: #000;
}
.toggle-check .control .control_indicator {
  margin-right: 0px !important;
}
.edit_square_icon {
  font-size: 18px;
  margin: 8px;
}
.img-upload-icon {
  position: absolute;
  right: 30px;
  top: 15px;
}
.img-edit-icon {
  position: absolute;
  right: 30px;
  top: 45px;
}
.img-upload-icon span i {
  color: #fff;
}
.input-example-card {
  font-size: 10px;
  height: 25px;
}
.color-bcbcbc {
  color: #bcbcbc !important;
}
.card-preview {
  // position: absolute; // Position-sticky
  position: relative;
  width: 410px;
  // top: 200px; // Position-sticky
  // right: 20%; // Position-sticky
  right: -20px;
  margin-left: auto;
  margin-bottom: 1rem;
  min-height: 93px;
  --gap: 0.5rem;
  background: #efe7dc;
  border: solid 1px #e0e0e0;
}
.card-preview .card-body {
  padding: 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;

  video {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  img, object {
    margin-left: auto;
    margin-right: auto;
  }

  .img-loading {
    width: 378px;
    height: 189px;
  }
}

.card-preview {
  overflow-y: auto;
  max-height: 500px;
}
.card-preview {
  background: #fff;
}
.btn-delete-all {
  font-size: 14px;
  color: #595959;
}
.btn-delete-all .material-symbols-outlined {
  font-size: 20px;
}
.action {
  padding: 0.5rem;
  padding-left: 0;

  *,
  button {
    font-weight: normal !important;
    color: #697077 !important;
  }

  button {
    background: transparent;
    border: none;
  }

  .control-group,
  .material-symbols-outlined {
    margin-right: 0.5rem;
  }
}
.all-actions .action {
  float: left;
}
.w-100 {
  width: 100% !important;
}
.no-item span{
  font-size: 70px !important;
  color: #bababa;
  margin-top: 20px;
}
.no-item p{
  font-size: 20px;
  color: #bababa;
}
.p-0 {
  padding: 0px;
}
</style>
